// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {cqMfKCtjx: {hover: true}};

const cycleOrder = ["cqMfKCtjx"];

const serializationHash = "framer-z9ld3"

const variantClassNames = {cqMfKCtjx: "framer-v-14f38pc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 70, delay: 0, mass: 1, stiffness: 120, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, link, width, ...props}) => { return {...props, EAxUUXZK_: image ?? props.EAxUUXZK_ ?? {src: "https://framerusercontent.com/images/vkjfOrNsDfei7ILfnek8xWjjY.webp?scale-down-to=512", srcSet: "https://framerusercontent.com/images/vkjfOrNsDfei7ILfnek8xWjjY.webp?scale-down-to=512 414w, https://framerusercontent.com/images/vkjfOrNsDfei7ILfnek8xWjjY.webp?scale-down-to=1024 829w, https://framerusercontent.com/images/vkjfOrNsDfei7ILfnek8xWjjY.webp 1080w"}, gnkAUx2gV: link ?? props.gnkAUx2gV} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gnkAUx2gV, EAxUUXZK_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cqMfKCtjx", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "cqMfKCtjx-hover") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-z9ld3", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={gnkAUx2gV}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 1333, intrinsicWidth: 1080, pixelHeight: 1333, pixelWidth: 1080, sizes: "min(249px, 100vw)", ...toResponsiveImage(EAxUUXZK_)}} className={`${cx("framer-14f38pc", className)} framer-1t0gx1c`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cqMfKCtjx"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"cqMfKCtjx-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-d385d4bf-a793-406c-8b4c-c1663f7e790f, rgb(220, 217, 202)))"}}>Hover effect</motion.p></React.Fragment>} className={"framer-1ac6uy1"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"T5Tg3zOGT"} style={{"--extracted-r6o4lv": "var(--token-d385d4bf-a793-406c-8b4c-c1663f7e790f, rgb(220, 217, 202))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/>)}</Image></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-z9ld3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-z9ld3 .framer-1t0gx1c { display: block; }", ".framer-z9ld3 .framer-14f38pc { cursor: pointer; height: 400px; overflow: hidden; position: relative; text-decoration: none; width: 249px; }", ".framer-z9ld3 .framer-1ac6uy1 { flex: none; height: 100%; left: calc(49.79919678714862% - 100% / 2); position: absolute; top: calc(50.00000000000002% - 100% / 2); white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 249
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"X6PcofYXO":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gnkAUx2gV":"link","EAxUUXZK_":"image"}
 * @framerImmutableVariables true
 */
const FramernoQsvEetN: React.ComponentType<Props> = withCSS(Component, css, "framer-z9ld3") as typeof Component;
export default FramernoQsvEetN;

FramernoQsvEetN.displayName = "Thumbanil";

FramernoQsvEetN.defaultProps = {height: 400, width: 249};

addPropertyControls(FramernoQsvEetN, {gnkAUx2gV: {title: "Link", type: ControlType.Link}, EAxUUXZK_: {__defaultAssetReference: "data:framer/asset-reference,vkjfOrNsDfei7ILfnek8xWjjY.webp?originalFilename=18b68bfbb32f6ad7fbd011.webp&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramernoQsvEetN, [{family: "Inter", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}])